import React, { useState, useEffect } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { useParams } from "react-router-dom";
import { FunctionFactory } from "survey-core";


function convertString(params) {
  return String(params);
}
FunctionFactory.Instance.register("convertString", convertString);



//request_id = f(client_id, question_id) , question_id
//8-character random string , 62 characters, s 
//generate random string 
//123456abc789 
// s+encode(question_id)
//
/*
/ axyz1423423akfjska-fjkasfjksajfk
/
*/

function SurveyComponent() {
  const [surveyModel, setSurveyModel] = useState(null);
  const [eventVars, setEventVars] = useState(null);


  const { urlid } = useParams(); //question_id_sd_uuid 1_fjksdfjksjdfk9
  const spiltedId = urlid.split('_sd_');//
  let questionId;
  if (spiltedId.length) {
    questionId = spiltedId[0];
  }
  const requestId = spiltedId.slice(1).join('');



  useEffect(() => {

    fetch('/geteventvars', {
      method: 'GET',
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', "response", response);
      }
      return response.json();
    })
      .then(response => {
        console.log("Loaded vars");
        let vars = {}
        try {
          vars = JSON.parse(response.serverResponse.data);
        } catch (error) {
          console.log("Can't parse the data from server ", error)
        }
        setEventVars(vars);

      })
      .catch(error => {
        console.error("Error submitting survey:", error);
      });


  }, [urlid])



  useEffect(() => {
    const fetchData = async () => {
      try {

        const json = await import(`../public/surveys/j_${questionId}.json`);
        const theme = await import(`../public/surveys/t_${questionId}.json`);
        const survey = new Model(json);
        survey.applyTheme(theme);
        survey.onComplete.add((sender, options) => {

          const surveyData = { "formData": sender.data, "requestId": requestId, "questionId": questionId };
          const submitUrl = '/submit';
          fetch(submitUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(surveyData),
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok', "response", response);
              }
              return response.json();
            })
            .then(data => {
              console.log("Survey submitted successfully:", data);

            })
            .catch(error => {
              console.error("Error submitting survey:", error);
            });



        });

        setSurveyModel(survey);
      } catch (error) {
        console.error("Failed to fetch survey data:", error);
      }
    };

    fetchData();
  }, [urlid]);

  if (!surveyModel || !eventVars) return <div>Loading survey...</div>;



  Object.entries(eventVars).forEach(([key, value]) => {
    surveyModel.setVariable(key, value)
  });




  return <Survey model={surveyModel} />;
}

export default SurveyComponent;
